import { ActionHandler } from "..";
import { saveAnswers } from "./saveAnswers";
import { trackEvent } from "src/utils/api/tracker";
import getStore from "src/utils/redux/store";

export const onCompleteCourseSurvey: ActionHandler = async (actionParams) => {
  const { serverContext } = getStore().getState();
  const surveyAnswers = await saveAnswers(actionParams);
  const eventParams = {
    isInTrial: serverContext.is_in_trial,
    upid: serverContext.upid,
    reason: serverContext.reason,
    course: serverContext.course,
  };
  trackEvent("FinishedCourseOfferSurvey", {
    ...surveyAnswers,
    ...eventParams,
  });
};
