import i18n from "@i18n";
import { isMaleGender } from "@utils/userSegment";

export enum genderConstants {
  male = "male",
  female = "female",
  nonBinary = "nonBinary",
  selfIdentify = "selfIdentify",
  preferNotToSay = "preferNotToSay",
}

export enum sexConstants {
  male = "male",
  female = "female",
  intersex = "intersex",
}

export const genderConstantsMapping = {
  [genderConstants.male]: "MALE",
  [genderConstants.female]: "FEMALE",
  [genderConstants.nonBinary]: "SELF_ID",
  [genderConstants.selfIdentify]: "SELF_ID",
  [genderConstants.preferNotToSay]: "UNKNOWN",
  UNKNOWN: "UNKNOWN",
};

export const getSingularGenderedNoun = (
  gender: genderConstants,
  lowercase = false
) => {
  let text: string;
  switch (gender) {
    case genderConstants.male:
      text = i18n.t("genderedText:maleSingularNoun");
      break;
    case genderConstants.female:
      text = i18n.t("genderedText:femaleSingularNoun");
      break;
    default:
      text = i18n.t("genderedText:neutralSingularNoun");
  }
  if (lowercase) {
    return text.toLowerCase();
  }
  return text;
};

export const getPluralGenderedNoun = (
  gender: genderConstants,
  lowercase = false
) => {
  let text: string;
  switch (gender) {
    case genderConstants.male:
      text = i18n.t("genderedText:malePluralNoun");
      break;
    case genderConstants.female:
      text = i18n.t("genderedText:femalePluralNoun");
      break;
    default:
      text = i18n.t("genderedText:neutralPluralNoun");
  }
  if (lowercase) {
    return text.toLowerCase();
  }
  return text;
};

export const getTextSuffixForMed = () => {
  const isMale = isMaleGender();

  return isMale ? "male" : "female";
};
