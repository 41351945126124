import React, { useState } from "react";
import { Switch } from "react-router";
import { Provider as ReduxProvider } from "react-redux";
import getStore from "@utils/redux/store";
import initializeApplication from "@utils/initialization";
import { QuickSilver } from "./test/quickSilver/loader";

import "./styles/global.less";
import { getRouterComponents } from "./router";
import { useOnce } from "./hooks/lifecycle";
import getRedirects from "./redirects";
import { PageSetGate } from "./hooks/pageSet";
import { subscribePersistence } from "./utils/redux/persistence";
import { AppInitProvider } from "./AppInitGate";
import { ErrorMessageProvider } from "./components/error/ErrorMessage";
import { CommonHead } from "./heads/Common";
import { SurveyContextProvider } from "./hooks/survey/context";
import { useNavTracker } from "./hooks/nav-tracker";
import { bootstrapConsent } from "./utils/consent";
import { trackAppInit, trackAppOpened } from "./hooks/tracking";
import { ScrollToTop } from "./components/scroll-to-top/ScrollToTop";
import { useAsyncError } from "./hooks/error";

import { QueryClientProvider } from "react-query";

// Bind concrete implementation to monitoring APIs
import "./utils/monitoring";
import { queryClient } from "./utils/fetch";
import { loadFeatureFlags } from "./pageDefinitions/featureFlags";
import { GlobalStyles } from "./styles/global";
import { measureTask } from "./utils/timing";
import { addMonitoringFlushHooks } from "@utils/monitoring/bus";
import LiveRegions from "./components/accessibility/LiveRegions";
import { AuthProblemModal } from "./components/AuthProblemModal";

loadFeatureFlags();
bootstrapConsent();

const appInitPromise = measureTask("init", initializeApplication);

const App = () => {
  useNavTracker();

  const [appInitialized, setAppInitialized] = useState(false);

  const asyncError = useAsyncError();

  useOnce(() => {
    const init = async () => {
      addMonitoringFlushHooks();
      subscribePersistence(getStore());
      trackAppOpened();
      await appInitPromise;
      // Finally allow application to render.
      setAppInitialized(true);
      trackAppInit();
    };
    init().catch(asyncError);
  });

  return (
    <>
      <CommonHead />
      <GlobalStyles />

      <AppInitProvider value={appInitialized}>
        <QueryClientProvider client={queryClient}>
          <ReduxProvider store={getStore()} identityFunctionCheck="never">
            <SurveyContextProvider>
              <PageSetGate>
                <ErrorMessageProvider>
                  <ScrollToTop />
                  <Switch>
                    {
                      // Note that we need to do the concat here vs. treating redirect and router components as
                      // their own components as Switch needs the elements to be direct children.
                      getRedirects().concat(getRouterComponents())
                    }
                  </Switch>
                  <AuthProblemModal />
                  <QuickSilver />
                </ErrorMessageProvider>
              </PageSetGate>
            </SurveyContextProvider>
          </ReduxProvider>
        </QueryClientProvider>
      </AppInitProvider>
      <LiveRegions />
    </>
  );
};

export default App;
