export const headConstants = {
  homepageHrefLangMappings: [
    { href: "https://www.noom.com/en-GB/", hreflang: "en-gb" },
    { href: "https://www.noom.com/en-AU/", hreflang: "en-au" },
    { href: "https://www.noom.com/en-NZ/", hreflang: "en-nz" },
    { href: "https://www.noom.com/de-DE/", hreflang: "de-de" },
    { href: "https://www.noom.com/es-ES/", hreflang: "es-es" },
    { href: "https://www.noom.com/en-IE/", hreflang: "en-ie" },
    { href: "https://www.noom.com/ja-JP/", hreflang: "ja-jp" },
    { href: "https://noom.co.kr/moneyback.html", hreflang: "ko-kr" },
    { href: "https://www.noom.com", hreflang: "en" },
    { href: "https://www.noom.com/ja/", hreflang: "ja" },
    { href: "https://www.noom.com/de/", hreflang: "de" },
    { href: "https://www.noom.com/es/", hreflang: "es" },
    { href: "https://www.noom.com", hreflang: "x-default" },
  ],
};

// Constants for visitorState property in visitorStatus state
export enum visitorLifecycleStates {
  newVisitor = "newVisitor",
  repeatVisitor = "repeatVisitor",
  lead = "lead",
  activeTrial = "activeTrial",
  activeSubscriber = "activeSubscriber",
  atRiskTrial = "atRiskTrial",
  atRiskSubscriber = "atRiskSubscriber",
  winbackEligible = "winbackEligible",
  unknown = "unknown",
}

export enum routeConstants {
  affiliateNoTrial = "aff-ntrl",
  affiliateNoTrialPromo = "aff-ntrl-prm",
  affiliateTrialNPB = "affiliate-trial-npb",

  app14DayFtWeb = "app-14-day-ft-web", // Android winback
  app14DayReturning = "app-14-day-returning", // iOS winback
  app14DayMain = "app-14-day-main", // iOS base
  app14DayWebMain = "app-14-day-web-main", // Android base
  appEmail = "app-email", // iOS email (mirrors exsf01/exsf01d)
  appFreemiumIU = "app-free", // Freemium: identified user
  appFreemiumQuiz = "app-personality-quiz",

  awsem = "awsem",
  baseline = "_baseline",
  dietary = "dietary",
  direct = "_direct",
  emailMain = "email-main",
  emailWinback = "email-winback",
  exsf01 = "exsf01",
  exsf01d = "exsf01d",
  hmAwsem = "hm-awsem",
  hmDirect = "hm-direct",
  hmEmail = "hm-email",
  hmExsf01 = "hm-exsf01",
  hmExsf01d = "hm-exsf01d",
  hmPaid = "hm-paid",
  hmWinback = "hm-winback",
  hmReferrals = "hm-referrals",
  ndpp = "ndpp",
  referrals = "referrals",
  supporters = "supporters",
  winback = "winback",

  // Added 7/7/21 from https://github.com/noom/growth/blob/552da1633e9f8689d849597cc8dc0b04ffbb7872/growth/flow/flows/en.py#L425
  control = "_control",
  experiment = "_experiment",
  phillips = "philips-airfryer",
  humana = "humana",
  affiliate = "affiliate-trial",
  freeTrial = "exft",
  appUpsell = "app-upsell",
  appEmployerSurvey = "app-employer",
  clinical = "clinical",
  freemiumClinical = "freemium-upsell-clinical",
  freemiumWeight = "freemium-upsell-weight",
  coachReferrals = "coach-referrals",
}

export enum hmActiveSubscriptionError {
  activeSubscription = "activeSubscription",
  nonUSSwitch = "nonUSSwitch",
}

export enum deviceOS {
  iOS = "iOS",
  android = "Android",
  macOS = "Mac OS",
}

export enum paymentSource {
  stripe = "STRIPE",
  braintree = "BRAINTREE",
  itunes = "ITUNES",
  googlePlay = "GOOGLE_PLAY",
}

export enum ClinicalIntakeAnswerMode {
  singleModuleOnly = "SINGLE_MODULE_ONLY",
  inAppTaskCard = "IN_APP_TASK_CARD",
  allModules = "ALL_MODULES",
}

export enum TelehealthInAppOnboardingTask {
  medicalIntake = "medicalIntake",
  insuranceInformation = "insuranceInformation",
  orderOrUploadLabs = "orderOrUploadLabs",
  completeLabs = "completeLabs",
  preferredPharmacy = "preferredPharmacy",
  scheduleConsult = "scheduleConsult",
}

// product names from https://github.com/noom/backend/blob/314072b9fbf90cfa0d93235230ef78b7e2f7197f/com.noom.spring.base/src/main/java/com/noom/spring/billing/model/Product.kt
export const NOOM_MED_ADDON_SUBSCRIPTION_PRODUCT_NAME = "TELEHEALTH_RECURRING";

export const NOOM_PREMIUM_BUNDLE_ID = "meal-workout-49";
export const NOOM_PREMIUM_BUNDLE_ID_DE = "meal-workout-49-de";
export const NOOM_PREMIUM_BUNDLE_ID_ES = "meal-workout-es";
export const NOOM_PREMIUM_BUNDLE_ID_ES_USD = "meal-workout-es-usd";
export const NOOM_PREMIUM_SUBSCRIPTION_ID_PREFIX = "meal-workout-sub";
export const NOOM_PREMIUM_TEXT_COACHING_SUBSCRIPTION_ID_PREFIX =
  "meal-workout-sub-ups_ex308-v1";
export const NOOM_SUMMIT_PRODUCT_ID = "nutrition-coaching-subscription";
export const MHMDA_STATES = ["NV", "CT", "WA"];

export const OFFERS_PURCHASE_URL = "/api/payment/v2/purchase_add_on/";
export const DISCOUNT_PROMO_CODE = "DISCOUNT25";
export const DISCOUNT_VALIDATION_URL = "/upsell_discounts/validate/";

export const IN_APP_TASK_PATIENT_CENTER_REFERRER = "patient_center";
export const BRANDED_MED_ESTIMATED_VALUE = 251;
export const COMPOUNDED_MED_ESTIMATED_VALUE = 721;
export const ORALS_METFORMIN_MED_ESIMATED_VALUE = 274.05;
export const NOOM_MED_ORALS_ACCEPTED_TERMS = "AcceptedTerms";
export const NOOM_MED_PURCHASE_GENERIC_EVENT_NAME = "Gardenia";
export const NOOM_MED_ORALS_PURCHASE_EVENT_NAME = "Orchid";
export const NOOM_MED_COMPOUNDED_PURCHASE_EVENT_NAME = "Carnation";
export const NOOM_MED_BRANDED_PURCHASE_EVENT_NAME = "Begonia";
export const STANDARD_PURCHASE_EVENT_NAME = "Purchase";
export const STANDARD_LEAD_EVENT_NAME = "Lead";
export const NOOM_MED_CUSTOM_EVENT_NAME = "MartagonLily";
export const NOOM_WEIGHT_CUSTOM_EVENT_NAME = "WaterLilly";
export const NOOM_WEIGHT_LEGACY_PURCHASE_CUSTOM_EVENT_NAME = "Wallflower";
export const NOOM_WEIGHT_PURCHASE_CUSTOM_EVENT_NAME = "Wisteria";

export const DEFAULT_APP_BRANCH_DEEPLINK =
  "https://noomcoach.app.link?open=LOGIN";

export enum SessionStorageKey {
  patientIntake = "patientIntake",
  patientResponsibilityEstimateUsd = "patientResponsibilityEstimateUsd",
  appointmentClinicianIds = "appointmentClinicianIds",
  appointmentStart = "appointmentStart",
  appointmentEnd = "appointmentEnd",
  checkoutTimerExpired = "checkoutTimerExpired",
  consultType = "consultType",
  // This is `true` for users that are scheduling their first consult.
  isNewPatient = "isNewPatient",
  // This is `true` for users that are new to Noom and do not have existing accounts.
  // This is `false` or undefined for users that have been sent an email to sign up for telehealth.
  isTelehealthBuyflow = "isTelehealthBuyflow",
  insuranceSurvey = "insuranceSurvey",
  userConfirmedIdentity = "userConfirmedIdentity",
  consentsForTelehealth = "consentsForTelehealth",
  telehealthConfirmedUser = "telehealthConfirmedUser",
  consentsForSms = "consentsForSms",
  phoneCode = "phoneCode",
  latestClinician = "latestClinician",
  sessionSurveyAnswers = "sessionSurveyAnswers",
  noomClinicalCheckoutData = "noomClinicalCheckoutData",
  consultGuidWaiting = "consultGuidWaiting",
  consultGuidFinishedOrScheduled = "consultGuidFinishedOrScheduled",
  timeslotIfSuccessfullyScheduled = "timeslotIfSuccessfullyScheduled",
  patientType = "patientType",
  // This state code is moreso the "current state code" which defaults to their home state.
  // Refer to patient intake session storage for persistent home state code storage.
  stateCode = "stateCode",
  // It was necessary to introduce this mode (singleModuleMode versus allModules)
  // for two reason:
  // 1. We need a way to provide short non-continuous survey experiences using
  // the landing link. So, if mode is "single module" we know to not advance
  // beyond the existing survey in most cases. If that mode is absent
  // or mode is "all modules" or the intake answer mode is not set, we assume
  // we should advance the user forward continuously.
  // 2. We could have re-used isTelehealthBuyflow however currently the
  // returning user experience is force-showing all anwered questions until
  // there is clarity on how we want to proceed. Once that is sorted out,
  // this answer mode may be able to be renamed to "singleModuleMode" as a
  // true/false value. Addressed in HC-1968
  IntakeAnswerMode = "IntakeAnswerMode",
  // upcomingAppointmentGuid is used for storing consult guid for consults that are
  // upcoming (not necessarily scheduled), unlike consultGuidFinishedOrScheduled which is used
  // for consults that are specifically in scheduled state or in finished state
  upcomingAppointmentGuid = "upcomingAppointmentGuid",
  canCancelVisit = "canCancelVisit",
  // Special use case for hasUpcomingVisit: on the appointment confirmation page,
  // we need to know if the user had a scheduled appointment prior to scheduling the one shown on
  // the confirmation page i.e. whether the most recent scheduling was an initial scheduling vs a
  // rescheduling scenario. It's not sufficient to rely on whether or not "reschedule" is in the URL
  // because it's possible to cancel in the reschedule survey.
  hasUpcomingVisit = "hasUpcomingVisit",
  isVideoVisitSurvey = "isVideoVisitSurvey",
  shouldSeeNewVideoVisitTermsPage = "shouldSeeNewVideoVisitTermsPage",
  trialTelehealthSubscriptionEndOfTrialTimeString = "trialTelehealthSubscriptionEndOfTrialTimeString",
  videoVisitFeeExperimentName = "videoVisitFeeExperimentName",
  videoVisitFeeExperimentVariant = "videoVisitFeeExperimentVariant",
  telehealthEligibleStates = "telehealthEligibleStates",
  mhmdaAnswer = "mhmdaAnswer",
  vibeInfo = "vibeInfo",
  b2bUserWithLabsIncluded = "b2bUserWithLabsIncluded",
  isLoggedInToZumba = "isLoggedInToZumba",
  createdLabOrderPaymentResponsibility = "createdLabOrderPaymentResponsibility",
  telehealthInAppTaskReferrer = "telehealthInAppTaskReferrer",
  whiteLabelIntakePreviousQuestionTitle = "whiteLabelIntakePreviousQuestionTitle",
  trackedCheckoutTermsAndConditions = "trackedCheckoutTermsAndConditions",
  lastGeneralNmcPurchaseRequest = "lastGeneralNmcPurchaseRequest",
  isTelehealthUpgradeFlow = "isTelehealthUpgradeFlow",
  singularPartnerUserId = "singularPartnerUserId",
}

export const DUMMY_MULTI_USER_PLAN_SECONDARY_EMAIL =
  "placeholder_exr128@noom.com";

export const ZUMBA_TRIAL_DURATIONS = [8, 15];

export const DEFAULT_TRIAL_PRICES = {
  US: [0.5, 3, 10, 18.37],
  CA: [1, 16, 24.41],
  GB: [1, 9, 14.41],
  AU: [1, 17, 26.41],
  NZ: [1, 18, 27.41],
  EU: [1, 11, 16.41],
};
export const TRIAL_PRICES_HM = [0.5, 3, 10, 17.27];

export const DEFAULT_NW_PLAN_DURATION_CLINICAL = 3;
