import { ActionHandler } from "..";
import { onStartSurvey } from "./onStartSurvey";
import { trackEvent } from "src/utils/api/tracker";
import getStore from "src/utils/redux/store";

export const onStartCourseSurvey: ActionHandler = (actionConfig) => {
  onStartSurvey(actionConfig);
  const { serverContext } = getStore().getState();

  trackEvent("StartedCourseOfferSurvey", {
    isInTrial: serverContext.is_in_trial,
    reason: serverContext.reason,
    course: serverContext.course,
    is_auto_renewal_enabled: serverContext.is_auto_renewal_enabled,
  });
};
