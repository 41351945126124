/* eslint-disable no-restricted-globals */

import { getMeristemContext, getRouteId } from "src/utils/meristemContext";
import getStore from "src/utils/redux/store";
import { captureException } from "src/utils/error";
import {
  isGooglePlayPlansEligible,
  isInAppNativePurchaseEligible,
  isITunesPlansEligible,
} from "src/utils/userSegment/features";
import goto from ".";
import {
  isInsideAndroidWebView,
  isInsideIOSWebKit,
  pageSetPushReroll,
  pushReroll,
} from "./utils";
import {
  legacyPurchaseRoot,
  setRecommendedPlanById,
} from "../../utils/redux/slices/recommendedPlan";
import {
  getAndroidMessageHandlers,
  getIOSMessageHandlers,
  IOSMessageHandlers,
  NativePurchaseInfo,
} from "src/utils/in-app/messageHandlers";
import { isHM } from "src/utils/userSegment";
import { getTelehealthPlanId } from "@utils/api/telehealth";

// "/purchase/:planId"
// "/purchase/(en|es|de)/:planId"
// "/purchase-hm/:planId"
function isPurchase() {
  return ["payment", "payment-survey", "payment-checkout"].includes(
    getMeristemContext().context_type
  );
}

export default {
  async purchaseSurvey(planId?: string) {
    if (planId) {
      await getStore().dispatch(setRecommendedPlanById(planId));
    }

    goto.layer("payment-survey");
  },

  async chooseTrialPrice(planId?: string) {
    if (planId) {
      await getStore().dispatch(setRecommendedPlanById(planId));
    }

    pageSetPushReroll("/payment/chooseTrialPrice");
  },

  payment() {
    pushReroll(`${legacyPurchaseRoot()}#/payment`, isPurchase);
  },

  async checkout(planId?: string) {
    if (planId) {
      await getStore().dispatch(setRecommendedPlanById(planId));
    }

    if (isHM()) {
      pageSetPushReroll(`/payment/hm/checkout`);
      return;
    }

    pushReroll(`${legacyPurchaseRoot()}#/checkout`, isPurchase);
  },

  async telehealthPaymentDetails() {
    const telehealthPlanId = getTelehealthPlanId();
    await getStore().dispatch(
      setRecommendedPlanById(telehealthPlanId, {
        overwriteForced: true,
      })
    );

    goto.checkout();
  },

  nativePurchase() {
    if (isInsideIOSWebKit()) {
      try {
        // NOTE(norbert): the buyflowPurchaseNoDetails endpoint was only introduced
        // in the iOS 9.14 release, so we need to check if it exists before calling it
        const messageHandlers = getIOSMessageHandlers();
        if (
          isInAppNativePurchaseEligible() &&
          messageHandlers.buyflowPurchaseNoDetails
        ) {
          // If app and route support web purchase, use that
          goto.layer("payment-results");
        } else {
          messageHandlers.buyflowPurchase.postMessage({});
        }
        return true;
      } catch (err) {
        // istanbul ignore next Error handling
        captureException(err, "nativePurchase.ios");
      }
    }

    if (isInsideAndroidWebView()) {
      try {
        const messageHandlers = getAndroidMessageHandlers();
        messageHandlers.buyflowPurchase();
        return true;
      } catch (err) {
        // istanbul ignore next Error handling
        captureException(err, "nativePurchase.android");
      }
    }

    // eslint-disable-next-line no-console
    console.error("nativePurchase: no message handler found");

    return false;
  },

  nativePurchaseNoDetails() {
    const state = getStore().getState();
    const { recommendedPlan, userData } = state;
    let sendPurchaseToNative: () => void | undefined;
    let productId: string;

    if (isInsideIOSWebKit()) {
      const messageHandlers = getIOSMessageHandlers();
      let messageHandler:
        | IOSMessageHandlers["buyflowPurchaseProduct"]
        | IOSMessageHandlers["buyflowPurchaseNoDetails"];

      if (isITunesPlansEligible()) {
        messageHandler =
          messageHandlers?.buyflowPurchaseProduct ||
          messageHandlers?.buyflowPurchaseNoDetails; // buyflowPurchaseProduct added in app version 10.9
      } else {
        messageHandler = messageHandlers?.buyflowPurchaseNoDetails;
      }

      productId = recommendedPlan.itunes_product_id || "";
      const productInfoPayload: NativePurchaseInfo = {
        productData: {
          productId,
          subscriptionLength: recommendedPlan.billing_cycle_duration,
        },
        programAvailability: {
          curriculum: "HW",
          isAvailable: true,
          language: userData.language,
        },
        tracking: {
          routeId: getRouteId(),
        },
      };
      sendPurchaseToNative =
        messageHandler &&
        (() => messageHandler.postMessage(productInfoPayload));
    } else if (isInsideAndroidWebView()) {
      const messageHandlers = getAndroidMessageHandlers();
      productId = recommendedPlan.android_product_id || "";
      const productInfoPayload: NativePurchaseInfo = {
        productData: {
          productId,
          subscriptionLength: recommendedPlan.billing_cycle_duration,
        },
        programAvailability: {
          curriculum: "HW",
          isAvailable: true,
          language: userData.language,
        },
        tracking: {
          routeId: getRouteId(),
        },
      };
      sendPurchaseToNative =
        messageHandlers.buyflowPurchaseProduct &&
        (() =>
          messageHandlers.buyflowPurchaseProduct(
            JSON.stringify(productInfoPayload)
          ));
    }

    const isProductIdRequired =
      isITunesPlansEligible() || isGooglePlayPlansEligible();
    if (isProductIdRequired && !productId) {
      captureException(
        new Error("No productId set for native purchase"),
        "nativePurchaseNoDetails"
      );
    }

    if (isInAppNativePurchaseEligible() && sendPurchaseToNative) {
      try {
        sendPurchaseToNative();
        return true;
      } catch (err) {
        captureException(err, "nativePurchaseNoDetails");
      }
    } else {
      captureException(
        new Error("Native bridge function not found"),
        "nativePurchaseNoDetails"
      );
    }

    // eslint-disable-next-line no-console
    console.error("nativePurchaseNoDetails: could not call message handler");

    return false;
  },

  nativePurchaseComplete() {
    if (isInsideIOSWebKit()) {
      try {
        (
          window as any
        ).webkit.messageHandlers.buyflowPurchaseCompleted.postMessage({});
        return true;
      } catch (err) {
        // istanbul ignore next Error handling
        captureException(err, "nativePurchaseComplete.ios");
      }
    }
    if (isInsideAndroidWebView()) {
      try {
        const messageHandlers = getAndroidMessageHandlers();
        messageHandlers.buyflowPurchaseCompleted();
        return true;
      } catch (err) {
        // istanbul ignore next Error handling
        captureException(err, "nativePurchaseComplete.android");
      }
    }

    // eslint-disable-next-line no-console
    console.error("nativePurchaseComplete: no message handler found");
    return false;
  },

  // Handle post-checkout navigation.
  navigateFromCheckout() {
    // If the user is ineligible for noom premium, they'll end up
    // in a failover pageset that will continue to the next eligible context after noom premium
    goto.noomPremium();
  },
};
