import { queryClient, send } from "../fetch";
import { Plan } from "@utils/redux/slices/plans";

/**
 * The values for equivalentMultiUserPlan and currentPlan contain just the picked properties from the big plan type.
 */
type PlanSubtype = Pick<
  Plan,
  | "billing_cycle_duration"
  | "discounted_plan_price"
  | "sales_tax_amount"
  | "braintree_id"
  | "currency"
>;

type EquivalentMultiUserPlanResponse = {
  equivalentMultiUserPlan: PlanSubtype;
  currentPlan: PlanSubtype;
  multiUserNoomPlanId: string;
};

type ProratedRefundDataResponse = {
  refundAmount: number;
  refundCurrency: string | null;
};

export type MultiUserPlanPeerResponse = {
  accessCode?: string;
  upid: string;
  isPrimaryUser: boolean;
  profileName?: string;
};

export const GET_PRORATED_REFUND_DATA_CACHE_KEY = ["proratedRefundData"];
export const GET_PEERS_CACHE_KEY = ["getPeers"];

export function makeEquivalentMultiUserPlanCacheKey(
  initialMultiUserNoomPlanId: string
) {
  return ["equivalentMultiUserPlan", initialMultiUserNoomPlanId];
}

export function getEquivalentMultiUserPlan(
  multiUserNoomPlanId: string
): Promise<EquivalentMultiUserPlanResponse> {
  const url = `/api/multiUserPlans/v2/equivalentMultiUserPlan/?multiUserNoomPlanId=${multiUserNoomPlanId}`;
  return send("GET", url);
}

export function getProratedRefundData(
  includePremiumAddon: boolean,
  subscriptionId?: string
): Promise<ProratedRefundDataResponse> {
  let url = `/api/plans/proratedRefundData?includePremiumAddon=${
    includePremiumAddon ? "true" : "false"
  }`;
  if (subscriptionId) {
    url += `&subscriptionId=${subscriptionId}`;
  }
  return send("GET", url);
}

export function getPeers(): Promise<MultiUserPlanPeerResponse[]> {
  return send("GET", "/api/multiUserPlans/peers");
}

export async function loadPeers() {
  return queryClient.prefetchQuery(GET_PEERS_CACHE_KEY, getPeers, { retry: 3 });
}
