import React, { useState } from "react";
import { FreeMWCPModal } from "./FreeMWCPModal";
import getStore from "src/utils/redux/store";
import { areSurveyAnswersDefined } from "src/utils/mainSurvey";
import {
  getBaselineTimerDuration,
  getOfferTimerDuration,
} from "src/utils/checkoutTimer";
import { loadWinbackInfo } from "src/utils/redux/slices/winbackInfo";
import { useOnce } from "src/hooks/lifecycle";
import { useAppDispatch } from "src/hooks/redux";
import { isReUp } from "src/utils/userSegment/features";
import visitTracker from "@noom/visit-tracker";

// this usually can be:
// - the first page in the main-survey
// - the choosePlan page
function isFirstSeenPage() {
  const initialUrlString = visitTracker.getTrackingParams()?.initialUrl;
  if (!initialUrlString) {
    return false;
  }
  const initialUrl = new URL(initialUrlString);
  if (
    initialUrl.pathname === "/ps/main-survey" ||
    initialUrl.pathname === "/survey/weightLossGoal"
  ) {
    return true;
  }
  if (initialUrl.pathname === "/survey/choosePlan") {
    return true;
  }
  return false;
}

function areBasicDetailsDefined() {
  const { userData, surveyAnswers } = getStore().getState();

  const { email } = userData;
  if (!email) {
    return false;
  }

  return areSurveyAnswersDefined(surveyAnswers, [
    "weightKg",
    "idealWeightKg",
    "heightCm",
  ]);
}

function wasFreeMWCPNotAnsweredBefore() {
  const { userData } = getStore().getState();
  if (!userData.initialExpirationDurationForFreeMWCP) {
    return true;
  }
  if (
    userData.initialExpirationDurationForFreeMWCP === getBaselineTimerDuration()
  ) {
    return true;
  }
  // we've shown the 1-hour free MWCP offer and they did not answer
  if (
    userData.initialExpirationDurationForFreeMWCP === getOfferTimerDuration() &&
    !userData.answerToFreeMWCPModal
  ) {
    return true;
  }

  return false;
}

function isMWCPOfferNotExpired() {
  const { userData } = getStore().getState();
  const { checkoutDiscountedMWCPTimerExpirationTimestamp } = userData;
  if (
    userData.initialExpirationDurationForFreeMWCP === getOfferTimerDuration() &&
    checkoutDiscountedMWCPTimerExpirationTimestamp
  ) {
    return checkoutDiscountedMWCPTimerExpirationTimestamp > Date.now();
  }
  return true;
}

function canSeeModal() {
  // checking this should cover the case of redirecting the user to the landing page.
  if (!areBasicDetailsDefined()) {
    return false;
  }
  return (
    wasFreeMWCPNotAnsweredBefore() &&
    isFirstSeenPage() &&
    isMWCPOfferNotExpired()
  );
}

// MWCP = Mental Wellness Course Pack
export function FreeMWCPModalEnabler() {
  const [showModal, setShowModal] = useState(canSeeModal());

  const dispatch = useAppDispatch();
  const [winbackInfoLoaded, setWinbackInfoLoaded] = useState(false);

  useOnce(() => {
    dispatch(loadWinbackInfo()).finally(() => {
      setWinbackInfoLoaded(true);
    });
  });

  if (showModal) {
    // Need an extra check for re-up here since winback info may not be loaded in before
    // the isEligibleForPreCheckoutFreeMWCPOffer() check to render this component.
    if (!winbackInfoLoaded || isReUp()) {
      return null;
    }
    return (
      <FreeMWCPModal
        onClose={() => {
          setShowModal(false);
        }}
      />
    );
  }

  return null;
}
