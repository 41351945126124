import { routeConstants } from "../constants";
import { checkSegments } from "./util";
import { isUS } from "./locale";
import { getInAppAutoCookie } from "@utils/authCookieParser";

export const isAppEmail = checkSegments({
  oneOf: [routeConstants.appEmail],
});

export const isAppIosBase = checkSegments({
  oneOf: [routeConstants.app14DayMain],
});

export const isAppAndroidBase = checkSegments({
  oneOf: [routeConstants.app14DayWebMain],
});

export const isAppIosWinback = checkSegments({
  oneOf: [routeConstants.app14DayReturning],
});

export const isAppAndroidWinback = checkSegments({
  oneOf: [routeConstants.app14DayFtWeb],
});

function isIOSPlatform() {
  const { platform } = getInAppAutoCookie() || {};
  return platform?.toUpperCase() === "IOS";
}

function isAndroidPlatform() {
  const { platform } = getInAppAutoCookie() || {};
  return platform?.toUpperCase() === "ANDROID";
}

export const isAppIOSFreemiumUpsell = checkSegments({
  oneOf: [routeConstants.freemiumClinical, routeConstants.freemiumWeight],
  allOf: [isIOSPlatform],
});

export const isAppIosBaseAndWinback = checkSegments({
  oneOf: [isAppIosBase, isAppIosWinback],
});

export const isAppIos = checkSegments({
  oneOf: [isAppIosBase, isAppIosWinback, isAppIOSFreemiumUpsell],
});

export const isAppAndroidFreemiumUpsell = checkSegments({
  allOf: [isAndroidPlatform],
  oneOf: [routeConstants.freemiumClinical, routeConstants.freemiumWeight],
});

export const isAppAndroid = checkSegments({
  oneOf: [isAppAndroidBase, isAppAndroidWinback, isAppAndroidFreemiumUpsell],
});

export const isAppBaseRoute = checkSegments({
  oneOf: [isAppIosBase, isAppAndroidBase],
});

export const isInAppWinback = checkSegments({
  oneOf: [isAppAndroidWinback, isAppIosWinback],
});

export const isAppFreemiumUpsell = checkSegments({
  oneOf: [isAppAndroidFreemiumUpsell, isAppIOSFreemiumUpsell],
});

export const isInApp = checkSegments({
  oneOf: [
    isInAppWinback,
    isAppBaseRoute,
    routeConstants.appUpsell,
    routeConstants.appFreemiumIU,
    isAppFreemiumUpsell,
  ],
});

export const isUSInAppWinback = checkSegments({
  allOf: [isUS],
  oneOf: [isInAppWinback],
});
