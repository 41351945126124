import {
  isFreeMonthsOfferEligible,
  isFreeMonthsPlanSelectEligible,
} from "@utils/userSegment/features";
import { Plan } from "@utils/redux/slices/plans";
import { AppDispatch, GetAppState } from "@utils/redux/internal";
import { trackTask } from "@utils/monitoring/events/tasks";
import { setRecommendedPlanById } from "@utils/redux/slices/recommendedPlan";
import { getURLParams } from "@utils/urlParams";
import { applyPromoCode } from "@utils/api/purchase/checkout";
import { getSessionState, updateSessionState } from "@pageDefinitions/session";
import { fetchProductPlanAsPlanById } from "@utils/plans";
import { clearPromoCode, setPromoCode } from "@utils/redux/slices/promoCode";
import { captureException } from "@utils/error";
import getStore from "./redux/store";

type FreeMonthsOfferConfiguration = {
  shorterPlanID: string;
  longerPlanID: string;
  discountPercentage: number;
};

declare module "src/pageDefinitions/session" {
  interface BrowserSessionState {
    freeMonthsOfferConfiguration?: FreeMonthsOfferConfiguration;
  }
}

export function loadFreeMonthsOfferConfiguration() {
  const { shorter_plan_id, noom_plan_id, discount_percentage } = getURLParams();
  if (shorter_plan_id && noom_plan_id && !isNaN(+discount_percentage)) {
    updateSessionState("browser", {
      freeMonthsOfferConfiguration: {
        shorterPlanID: shorter_plan_id,
        longerPlanID: noom_plan_id,
        discountPercentage: +discount_percentage,
      },
    });
  }
}

/**
 * Returns the configuration used for determining a free months offer
 * In case the configuration is not saved in session storage we load it from query params
 */
export function freeMonthsOfferConfiguration() {
  if (!isFreeMonthsOfferEligible(null)) {
    return null;
  }

  const configuration = getSessionState("browser").freeMonthsOfferConfiguration;
  if (configuration) {
    return configuration;
  }

  loadFreeMonthsOfferConfiguration();
  return getSessionState("browser").freeMonthsOfferConfiguration;
}

/**
 * Typescript definition of our promo code replacement system.
 * For international customers we have to apply a different promo code to achieve the discount
 * promised in the emails.
 *
 * The map has the following structure:
 * - CurrencyCode = currency definition for the accepted currency codes
 * - PromoCodeMapping = An array of key-value pairs between the currency codes and the actual code we have to apply
 * - DiscountPercentageCategory = an array of key-value pairs between the discount percentages and the correspondent PromoCodeMapping
 * - ShorterPlanLengthCategory = an array of key-value pairs between the length of the shorter plan and the correspondent DiscountPercentageCategory
 * - ReplacementPromoCodes = an array of key-value pairs between the length of the longer plan and the correspondent ShorterPlanLengthCategory
 *
 * !!! This map has to be adapted if we change the pricing schema for any international country or if we change the promotional emails !!!
 *
 */
type CurrencyCode = "CAD" | "GBP" | "EUR" | "AUD" | "NZD" | "USD";

type PromoCodeMapping = {
  [currency in CurrencyCode]: string | null;
};

type DiscountPercentageCategory = {
  [discountPercentage: string]: PromoCodeMapping;
};

type ShorterPlanLengthCategory = {
  [shorterPlanLength: string]: DiscountPercentageCategory;
};

type ReplacementPromoCodes = {
  [longerPlanLength: string]: ShorterPlanLengthCategory;
};

const PROMO_CODE_USD_20_OFF = "USD20OFF";
const PROMO_CODE_USD_30_OFF = "USD30OFF";

const SAME_PROMO = null;

const REPLACEMENT_PROMO_CODES: ReplacementPromoCodes = {
  "6": {
    "2": {
      "30": {
        CAD: "CAD80OFF",
        GBP: "GBP65OFF",
        EUR: "EUR40OFF",
        AUD: "AUD70OFF",
        NZD: "NZD70OFF",
        USD: SAME_PROMO,
      },
    },
    "3": {
      "25": {
        CAD: "CAD70OFF",
        GBP: "GBP55OFF",
        EUR: SAME_PROMO,
        AUD: "AUD50OFF",
        NZD: SAME_PROMO,
        USD: SAME_PROMO,
      },
    },
  },
  "8": {
    "4": {
      "12": {
        CAD: "CAD60OFF",
        GBP: "GBP39OFF",
        EUR: "EUR20OFF",
        AUD: "AUD60OFF",
        NZD: "NZD50OFF",
        USD: SAME_PROMO,
      },
    },
  },
  "12": {
    "4": {
      "21": {
        CAD: "CAD110OFF",
        GBP: "GBP71OFF",
        EUR: "EUR55OFF",
        AUD: "AUD110OFF",
        NZD: "NZD90OFF",
        USD: SAME_PROMO,
      },
      "25": {
        CAD: "CAD110OFF",
        GBP: "GBP71OFF",
        EUR: "EUR55OFF",
        AUD: "AUD110OFF",
        NZD: "NZD90OFF",
        USD: SAME_PROMO,
      },
    },
    "5": {
      "18": {
        CAD: "CAD90OFF",
        GBP: "GBP60OFF",
        EUR: "EUR50OFF",
        AUD: "AUD90OFF",
        NZD: "NZD80OFF",
        USD: SAME_PROMO,
      },
      "12": {
        CAD: "CAD90OFF",
        GBP: "GBP60OFF",
        EUR: "EUR50OFF",
        AUD: "AUD90OFF",
        NZD: "NZD80OFF",
        USD: SAME_PROMO,
      },
      "22": {
        CAD: "CAD90OFF",
        GBP: "GBP60OFF",
        EUR: "EUR50OFF",
        AUD: "AUD90OFF",
        NZD: "NZD80OFF",
        USD: SAME_PROMO,
      },
    },
    "6": {
      "20": {
        CAD: "CAD70OFF",
        GBP: "GBP45OFF",
        EUR: "EUR45OFF",
        AUD: "AUD70OFF",
        NZD: "NZD60OFF",
        USD: SAME_PROMO,
      },
    },
  },
};

export function getReplacementPromoCode(
  longerPlan: Partial<Plan>,
  shorterPlan: Partial<Plan>,
  promoCode: string,
  promoCodePercentage?: string
) {
  if (isFreeMonthsPlanSelectEligible()) {
    if (
      longerPlan?.plan_price - shorterPlan?.plan_price === 20 &&
      longerPlan.billing_cycle_duration === 6
    ) {
      return PROMO_CODE_USD_20_OFF;
    }
    if (
      longerPlan?.plan_price - shorterPlan?.plan_price === 30 &&
      longerPlan.billing_cycle_duration === 12
    ) {
      return PROMO_CODE_USD_30_OFF;
    }
  }

  const replacementPromoCode =
    REPLACEMENT_PROMO_CODES[longerPlan.billing_cycle_duration]?.[
      shorterPlan.billing_cycle_duration
    ]?.[promoCodePercentage]?.[longerPlan.currency];

  if (!replacementPromoCode || replacementPromoCode === SAME_PROMO) {
    return promoCode;
  }

  return replacementPromoCode;
}

/**
 * A plan is eligible for the free months offer if the promo code discount percentage applied on it matches
 * the one defined in the query param discount_percentage. And his id matches shorter_plan_id from query params
 */
export function isPlanEligibleForFreeMonthsOffer(plan: Partial<Plan> | null) {
  const freeMonthsOffer = freeMonthsOfferConfiguration();

  return (
    isFreeMonthsOfferEligible() &&
    freeMonthsOffer &&
    freeMonthsOffer.discountPercentage ===
      plan?.discount_info?.discountPercent &&
    (freeMonthsOffer.shorterPlanID === plan?.noom_plan_id ||
      isFreeMonthsPlanSelectEligible())
  );
}

async function getFreeMonthOfferPlans(state = getStore().getState()) {
  const { planOptions } = state;

  let shorterPlan: Plan;
  let longerPlan: Plan;
  if (isFreeMonthsPlanSelectEligible()) {
    shorterPlan = planOptions.middle;
    longerPlan = planOptions.preSelected;
  }
  const { shorterPlanID, longerPlanID } = freeMonthsOfferConfiguration();

  shorterPlan =
    shorterPlan ?? (await fetchProductPlanAsPlanById(shorterPlanID));
  longerPlan = longerPlan ?? (await fetchProductPlanAsPlanById(longerPlanID));

  return { shorterPlan, longerPlan, shorterPlanID, longerPlanID };
}

/**
 * Free months offers are email campaigns sent to users that give them the impression
 * they received free months on their plan when they apply a promo code.
 * This offer is used in 2 different cases:
 * 1. On US email-main we show users coming from a free months offer email 3 different plans in a plan selection UI:
 *   - Monthly Plan
 *   - Shorter Plan (3 or 6 months)
 *   - Longer Plan (6 or 12 months)
 *   When the user applies a promocode, we validate this promocode against the discount percentage sent in the query param.
 *   If the promocode is valid, we swap to a $20 or $30 off promocode behind the scenes applied only on the longer plan
 *   to make the longer plan price equal to the shorter plan price
 *
 * 2. On email-winback and Intl email-main we immediately force these users onto the shorter plan. If they apply a valid promocode that
 *   matches the discount percentage, we swap their plan over to the longer plan and apply the promocode to the longer plan.
 *   For intl, we replace the original promocode applied with a different promocode to fit the pricing differences.
 *   The end result is that they will be on the longer plan with a price that is cheaper or equal to the shorter plan.
 *
 *  The feature is defined by 3 query params:
 * - shorter_plan_id Plan id for the shorter plan
 * - discount_percentage The discount percentage of the original promocode that came with their email
 * - noom_plan_id Plan id for the longer plan
 * If applying the promo code on the longer plan fails we keep it applied on the first plan as fallback.
 * @param discountedPlan The plan with promo code applied
 * @param promoCode The promo code that was applied
 */
export function applyFreeMonthsOffer(
  discountedPlan: Partial<Plan> | null,
  promoCode: string
) {
  return async (dispatch: AppDispatch, getState: GetAppState) => {
    const state = getState();
    const { promoCode: promoCodeSlice, recommendedPlan } = state;

    const task = trackTask("ApplyFreeMonthsOffer");

    try {
      task.start();

      const initialPromoExpirationDate = promoCodeSlice.promoCodeExpirationDate;
      // clear promo code applied on shorter plan, so we don't display it on UI
      await dispatch(clearPromoCode());

      const { shorterPlan, longerPlan, shorterPlanID, longerPlanID } =
        await getFreeMonthOfferPlans(state);

      if (!shorterPlan) {
        throw new Error(
          `No plan found with noom_plan_id for free months offer: ${shorterPlanID}`
        );
      }

      if (!longerPlan) {
        throw new Error(
          `No plan found with noom_plan_id for free months offer: ${longerPlanID}`
        );
      }
      const longerPlanDuration = longerPlan.billing_cycle_duration;
      const freeMonths =
        longerPlanDuration - shorterPlan.billing_cycle_duration;

      if (freeMonths <= 0) {
        throw new Error(
          `Invalid free months offer configuration freeMonths: ${freeMonths}`
        );
      }

      const newPromoCode = getReplacementPromoCode(
        longerPlan,
        shorterPlan,
        promoCode,
        `${discountedPlan?.discount_info?.discountPercent}`
      );
      dispatch(
        setPromoCode({
          promoCodeInitialCode: promoCode,
          promoCodeExpirationDate: initialPromoExpirationDate,
        })
      );

      const longerPlanDiscounted = await dispatch(
        applyPromoCode(
          {
            ...longerPlan,
            // keep trial fee
            trial_fee: recommendedPlan.trial_fee,
          },
          newPromoCode,
          freeMonths,
          // Don't update the expiration date so that single use promocodes still work as intended
          false
        )
      );

      if (!longerPlanDiscounted) {
        throw new Error(
          `Promo code ${promoCode} couldn't be applied for free months offer on plan ${longerPlanID}`
        );
      }

      if (
        !isFreeMonthsPlanSelectEligible() ||
        recommendedPlan.noom_plan_id === longerPlanID
      ) {
        await dispatch(
          setRecommendedPlanById(longerPlanID, {
            overwriteForced: true,
            keepPromoCode: true,
          })
        );
      }

      task.success();
      return longerPlanDiscounted;
    } catch (err) {
      captureException(err);
      task.failed(err);
      // restore the previous promo code
      dispatch(setPromoCode(promoCodeSlice));
      return discountedPlan;
    }
  };
}
