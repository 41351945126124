import { BillingIntervalProto_Unit } from "@noom/noom-contracts/noom_contracts/billing/billing_interval";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { paymentSource } from "src/utils/constants";

export interface DiscountInfo {
  code: string;
  discountType: "PERCENT" | "AMOUNT";
  /** Present if discountType === PERCENT, otherwise 0
   */
  discountAmount: number;
  /** Present if discountType === AMOUNT, otherwise 0
   */
  discountPercent: number;
  /** According to the backend:
   * Current definition of a VIP is someone who gets 100% discount and is in the VIP batch.
   * Codes that give free plans but aren't in the batch are not considered VIP.
   */
  isFreePlan: boolean;
}

export interface Plan {
  batch_symbol: string | null;
  braintree_id: string;
  currency: string;
  currency_symbol: string;
  discounted_plan_price: number;
  formatted_promo_discount_amount: string;
  billing_cycle_duration: number;
  billing_cycle_duration_units: BillingIntervalProto_Unit;
  gross_price?: string;
  has_trial: boolean;
  is_vip: boolean;
  ltv: number;
  lv: number;
  merchant_account: string;
  plan_price: number;
  price: number;
  promo_discount_amount: number;
  promo_code_expiration_date?: string;
  regular_price: number;
  sales_tax_amount: number;
  monthly_price: number;
  discounted_monthly_price: number;
  regular_monthly_price: number;
  weekly_price: number;
  discounted_weekly_price: number;
  regular_weekly_price: number;
  starter_fee: number;
  trial_fee: number;
  noom_plan_id: string;
  trial_duration: number;
  payment_source?: paymentSource.braintree | paymentSource.stripe;
  curriculum?: string;

  trial_item_options?: {
    price: number;
    trial_sale_item_id: string;
  }[];
  sale_item_id?: string;
  one_source_tax_code?: string;

  itunes_product_id?: string;
  android_product_id?: string;
  discount_info?: DiscountInfo;
  trial_fee_total_amount?: number;
}

export type PlansState = Record<string, Plan>;

const plansSlice = createSlice({
  name: "plans",
  initialState: {} as PlansState,
  reducers: {
    updatePlans(state, action: PayloadAction<PlansState>) {
      return { ...state, ...action.payload };
    },
  },
});

export const { updatePlans } = plansSlice.actions;

export default plansSlice;
