import { trackEvent } from "@utils/api/tracker";
import { fireFacebookCapi } from "@utils/api/FacebookCapiApi";
import { generateEventId } from "@utils/pixels/utils";
import { shouldAdjustELTV, adjustELTV, extractAdName } from "../eltvAdjustment";
import { registerHandler } from "../handler";
import {
  allowCategories,
  consentFramework,
  LibCategories,
} from "src/utils/consent";
import { getCountryCode } from "src/utils/meristemContext";
import { optOutAllowsCookies } from "src/utils/consent/opt-out";
import { shouldFireMedPixel } from "src/utils/userSegment/features";
import {
  NOOM_MED_CUSTOM_EVENT_NAME,
  NOOM_MED_ORALS_ACCEPTED_TERMS,
  NOOM_MED_PURCHASE_GENERIC_EVENT_NAME,
  NOOM_WEIGHT_CUSTOM_EVENT_NAME,
  NOOM_WEIGHT_LEGACY_PURCHASE_CUSTOM_EVENT_NAME,
  NOOM_WEIGHT_PURCHASE_CUSTOM_EVENT_NAME,
  STANDARD_LEAD_EVENT_NAME,
  STANDARD_PURCHASE_EVENT_NAME,
} from "src/utils/constants";

declare const fbq: any;

interface FacebookAdvancedMatchingParams {
  em?: string;
  fn?: string;
  ln?: string;
  email?: string;
  external_id?: string;
  ph?: string;
}

export interface FacebookPixelParameters {
  order_id?: string;
  external_id?: string;
  content_category?: string;
  content_ids?: string;
  content_type?: string;
  value?: number;
  currency?: string;
  product?: string;
}

const FB_ELTV13_PIXEL = "1557275494440599";
const FB_FORBES_PIXEL = "830806628259122";
const NOOM_WEIGHT_META_PIXEL = "783435153677650";
const NOOM_MED_META_PIXEL = "540736718278208";

/**
 * Determines which purchase events pixel we need to fire for med.
 */
export function getMedPurchaseEvents(
  isOralsPurchase: boolean,
  customEventName?: string
): string[] {
  // We need to fire several events that have the same parameters as the regular Purchase
  const eventsToBeFired = [
    // In the Orals case AcceptedTerms is used instead of purchase for Optimization purposes
    isOralsPurchase
      ? NOOM_MED_ORALS_ACCEPTED_TERMS
      : STANDARD_PURCHASE_EVENT_NAME,
    NOOM_MED_PURCHASE_GENERIC_EVENT_NAME,
  ];
  if (customEventName) {
    eventsToBeFired.push(customEventName);
  }
  return eventsToBeFired;
}

/**
 * Determines which META pixel we need to fire.
 */
function getMetaPixelId() {
  if (shouldFireMedPixel()) {
    return NOOM_MED_META_PIXEL;
  }
  return NOOM_WEIGHT_META_PIXEL;
}

/**
 * Determines which lead events should we fire.
 */
export function getLeadEvents(): string[] {
  const eventsToBeFired = [STANDARD_LEAD_EVENT_NAME];
  if (shouldFireMedPixel()) {
    eventsToBeFired.push(NOOM_MED_CUSTOM_EVENT_NAME);
  } else {
    eventsToBeFired.push(NOOM_WEIGHT_CUSTOM_EVENT_NAME);
  }
  return eventsToBeFired;
}

/**
 * Enables the advance matching feature for Facebook Pixel.
 */
function initAdvancedMatching(PIXEL_ID: string, userId: string) {
  const advancedMatchingParams: FacebookAdvancedMatchingParams = {
    ...(userId && { external_id: userId }),
  };

  // NOTE(Patrick): We already do this in the `init` handler but the logic
  // in ConversionTracker fires it twice so leaving it in.
  // Check to add LDU flag for CCPA opt out.
  if (shouldApplyLDUFlag()) {
    applyLDUFlag();
  }

  fbq("init", PIXEL_ID, advancedMatchingParams);
}

/**
 * Workaround for being able to add information as advanced matching latter in the funnel (if the purchase is successful)
 * This would have not been possible otherwise as the advanced matching parameters can only be added at the first call of 'init'
 */
function reinitForMatchingParameters(PIXEL_ID) {
  if (allowCategories([LibCategories.targetingCookies])) {
    const script = document.createElement("script");
    script.type = "text/javascript";
    script.src = `https://connect.facebook.net/signals/config/${PIXEL_ID}?v=2.9.48&r=stable`;
    script.id = "facebookEltv13PixelScript";

    const head = document.getElementsByTagName("head")[0];
    head.appendChild(script);
  }
}

export function shouldApplyLDUFlag() {
  return consentFramework() === "optOut" && !optOutAllowsCookies();
}

export function applyLDUFlag() {
  fbq("dataProcessingOptions", ["LDU"], 1, 1000);
}

export default function registerHandlers() {
  registerHandler("init", () => {
    const META_PIXEL_ID = getMetaPixelId();
    // Check to add LDU flag for CCPA opt out.
    if (shouldApplyLDUFlag()) {
      applyLDUFlag();
    }

    fbq("init", FB_ELTV13_PIXEL);
    fbq("init", FB_FORBES_PIXEL);
    fbq("init", META_PIXEL_ID);

    // For Meta We are using 2 pixels. The old FB_ELTV13_PIXEL
    // and either NOOM_MED_META_PIXEL/NOOM_WEIGHT_META_PIXEL depending on the route.
    // The Forbes pixel is just using the Meta platform
    const eventIDEltv13Payload = { eventID: generateEventId() };
    fbq("trackSingle", FB_ELTV13_PIXEL, "PageView", {}, eventIDEltv13Payload);
    fireFacebookCapi(FB_ELTV13_PIXEL, "PageView", eventIDEltv13Payload);

    const eventIDNewPixelPayload = { eventID: generateEventId() };
    fbq("trackSingle", META_PIXEL_ID, "PageView", {}, eventIDNewPixelPayload);
    fireFacebookCapi(META_PIXEL_ID, "PageView", eventIDNewPixelPayload);

    fbq("trackSingle", FB_FORBES_PIXEL, "PageView");
  });

  registerHandler("viewContent", (params) => {
    const META_PIXEL_ID = getMetaPixelId();

    const oldPixeleventID = generateEventId(params.userId);
    fbq("trackSingle", FB_ELTV13_PIXEL, "ViewContent", params, {
      eventID: oldPixeleventID,
    });
    fireFacebookCapi(FB_ELTV13_PIXEL, "ViewContent", {
      value: params.value,
      eventID: oldPixeleventID,
      currency: params.currency,
      external_id: params.userId,
    });

    const newPixelEventId = generateEventId(params.userId);
    fbq("trackSingle", META_PIXEL_ID, "ViewContent", params, {
      eventID: newPixelEventId,
    });
    fireFacebookCapi(META_PIXEL_ID, "ViewContent", {
      value: params.value,
      eventID: newPixelEventId,
      currency: params.currency,
      external_id: params.userId,
    });
  });

  registerHandler("lead", ({ emailHashes, userId, consentStatus }) => {
    const META_PIXEL_ID = getMetaPixelId();
    const eventID = generateEventId(userId);

    initAdvancedMatching(META_PIXEL_ID, userId);
    initAdvancedMatching(FB_ELTV13_PIXEL, userId);

    const fbParams: JsonObject = {
      ...consentStatus,
    };

    fbq("trackSingle", FB_ELTV13_PIXEL, "Lead", fbParams, { eventID });
    fireFacebookCapi(FB_ELTV13_PIXEL, "Lead", {
      customProperties: {
        ...fbParams,
      },
      external_id: userId,
      eventID,
      email: emailHashes.sha256,
    });
    const leadEvents = getLeadEvents();
    leadEvents.forEach((currentEventName) => {
      const currentPixelEventId = generateEventId(userId);
      fbq("trackSingle", META_PIXEL_ID, currentEventName, fbParams, {
        currentPixelEventId,
      });
      fireFacebookCapi(META_PIXEL_ID, currentEventName, {
        customProperties: {
          ...fbParams,
        },
        external_id: userId,
        eventID: currentPixelEventId,
        email: emailHashes.sha256,
      });
    });
  });

  registerHandler(
    "purchase",
    async ({ emailHashes, order, plan, consentStatus, userId }) => {
      // refresh facebook scripts as early as possible
      reinitForMatchingParameters(FB_ELTV13_PIXEL);
      reinitForMatchingParameters(NOOM_WEIGHT_META_PIXEL);

      const eltv13Value = shouldAdjustELTV()
        ? adjustELTV(order.eltv_13_months, getCountryCode())
        : order.eltv_13_months;

      if (eltv13Value !== order.eltv_13_months) {
        trackEvent("AdjustedELTV13", {
          originalValue: order.eltv_13_months,
          adjustedValue: eltv13Value,
          adName: extractAdName(),
        });
      }

      const regularPixelProperties: FacebookPixelParameters = {
        ...(order.subscriptionId && { order_id: order.subscriptionId }),
        ...consentStatus,
        content_ids: plan.braintree_id,
        content_type: "product",
        content_category: "recurring",
        external_id: userId,
      };

      const pixelPropertiesWithValue: FacebookPixelParameters = {
        ...regularPixelProperties,
        value: eltv13Value,
        currency: "USD",
      };

      fbq("init", FB_ELTV13_PIXEL, {
        em: emailHashes.sha256,
      });

      // Fire legacy purchase events
      [
        STANDARD_PURCHASE_EVENT_NAME,
        NOOM_WEIGHT_LEGACY_PURCHASE_CUSTOM_EVENT_NAME,
      ].forEach(async (currentEventName) => {
        const currentPixelEventId = generateEventId(userId);
        const currentPixelProperties =
          currentEventName === STANDARD_PURCHASE_EVENT_NAME
            ? pixelPropertiesWithValue
            : regularPixelProperties;
        fbq(
          "trackSingle",
          FB_ELTV13_PIXEL,
          currentEventName,
          currentPixelProperties,
          {
            eventID: currentPixelEventId,
          }
        );
        await fireFacebookCapi(FB_ELTV13_PIXEL, currentEventName, {
          ...currentPixelProperties,
          ...consentStatus,
          eventID: currentPixelEventId,
          email: emailHashes.sha256,
        });
      });

      fbq("init", NOOM_WEIGHT_META_PIXEL, {
        em: emailHashes.sha256,
      });

      // Fire new pixel purchase events
      [
        STANDARD_PURCHASE_EVENT_NAME,
        NOOM_WEIGHT_PURCHASE_CUSTOM_EVENT_NAME,
      ].forEach(async (currentEventName) => {
        const currentPixelEventId = generateEventId(userId);
        const currentPixelProperties =
          currentEventName === STANDARD_PURCHASE_EVENT_NAME
            ? pixelPropertiesWithValue
            : regularPixelProperties;
        fbq(
          "trackSingle",
          NOOM_WEIGHT_META_PIXEL,
          currentEventName,
          currentPixelProperties,
          {
            eventID: currentPixelEventId,
          }
        );
        await fireFacebookCapi(NOOM_WEIGHT_META_PIXEL, currentEventName, {
          ...currentPixelProperties,
          ...consentStatus,
          eventID: currentPixelEventId,
          email: emailHashes.sha256,
        });
      });

      fbq("trackSingle", FB_FORBES_PIXEL, STANDARD_PURCHASE_EVENT_NAME);
    }
  );

  registerHandler(
    "purchaseIntent",
    ({
      emailHashes,
      plan,
      consentStatus,
      userId,
      estimatedValue,
      isOralsPurchase,
      customEventName,
    }) => {
      // refresh facebook script as early as possible
      reinitForMatchingParameters(NOOM_MED_META_PIXEL);
      const pixelProperties: FacebookPixelParameters = {
        ...consentStatus,
        external_id: userId,
        content_ids: plan.braintree_id,
        content_type: "product",
        content_category: "recurring",
      };
      const pixelPropertiesWithValue = {
        ...pixelProperties,
        value: estimatedValue || plan.plan_price,
        currency: "USD",
      };
      // In the case of non-Orals med purchases we need to fire StartTrial
      if (!isOralsPurchase) {
        const startTrialEventID = generateEventId(userId);
        fbq(
          "trackSingle",
          NOOM_MED_META_PIXEL,
          "StartTrial",
          pixelPropertiesWithValue,
          {
            eventID: startTrialEventID,
          }
        );
        fireFacebookCapi(NOOM_MED_META_PIXEL, "StartTrial", {
          ...pixelPropertiesWithValue,
          eventID: startTrialEventID,
          email: emailHashes.sha256,
        });
      }
      fbq("init", NOOM_MED_META_PIXEL, {
        em: emailHashes.sha256,
      });
      const purchaseEvents = getMedPurchaseEvents(
        isOralsPurchase,
        customEventName
      );
      purchaseEvents.forEach((currentEventName) => {
        const currentEventId = generateEventId(userId);
        const currentPixelProperties =
          currentEventName === STANDARD_PURCHASE_EVENT_NAME ||
          currentEventName === NOOM_MED_ORALS_ACCEPTED_TERMS
            ? pixelPropertiesWithValue
            : pixelProperties;
        fbq(
          "trackSingle",
          NOOM_MED_META_PIXEL,
          currentEventName,
          currentPixelProperties,
          {
            eventID: currentEventId,
          }
        );
        fireFacebookCapi(NOOM_MED_META_PIXEL, currentEventName, {
          ...currentPixelProperties,
          ...consentStatus,
          eventID: currentEventId,
          email: emailHashes.sha256,
        });
      });
    }
  );

  registerHandler(
    "trialFastForward",
    ({ discountOffered, userId, emailHashes }) => {
      const legacyPixelEventID = generateEventId(userId);

      const customProperties = {
        discountOffered,
      };
      const regularProperties = {
        external_id: userId,
        eventID: legacyPixelEventID,
      };
      const capiProperties = {
        email: emailHashes.sha256,
      };

      // Legacy events
      // Re-init the pixel
      reinitForMatchingParameters(FB_ELTV13_PIXEL);
      fbq("init", FB_ELTV13_PIXEL, {
        em: emailHashes.sha256,
      });

      const legacyPixelProperties: FacebookPixelParameters = {
        ...customProperties,
        ...regularProperties,
      };

      fbq(
        "trackSingle",
        FB_ELTV13_PIXEL,
        "TrialFastForward",
        legacyPixelProperties,
        {
          eventID: legacyPixelEventID,
        }
      );
      fireFacebookCapi(FB_ELTV13_PIXEL, "TrialFastForward", {
        ...legacyPixelProperties,
        ...capiProperties,
        customProperties,
      });

      // New pixel events
      const newPixelEventID = generateEventId(userId);
      regularProperties.eventID = newPixelEventID;
      // Re-init the pixel
      reinitForMatchingParameters(NOOM_WEIGHT_META_PIXEL);
      fbq("init", NOOM_WEIGHT_META_PIXEL, {
        em: emailHashes.sha256,
      });

      const newpixelProperties: FacebookPixelParameters = {
        ...customProperties,
        ...regularProperties,
      };

      fbq(
        "trackSingle",
        NOOM_WEIGHT_META_PIXEL,
        "TrialFastForward",
        newpixelProperties,
        {
          eventID: newPixelEventID,
        }
      );
      fireFacebookCapi(NOOM_WEIGHT_META_PIXEL, "TrialFastForward", {
        ...newpixelProperties,
        ...capiProperties,
        customProperties,
      });
    }
  );

  registerHandler(
    "noomPremium",
    ({ signupEltv13, products, userId, emailHashes }) => {
      // Legacy events
      const eventID = generateEventId(userId);
      const pixelProperties = {
        value: signupEltv13 + 40,
        external_id: userId,
      };
      fbq(
        "trackSingle",
        FB_ELTV13_PIXEL,
        "PurchasedAddOns",
        {
          ...pixelProperties,
          products,
        },
        {
          eventID,
        }
      );

      const customProperties = {
        products,
      };
      const capiProperties = {
        email: emailHashes.sha256,
        eventID,
      };

      fireFacebookCapi(FB_ELTV13_PIXEL, "PurchasedAddOns", {
        ...pixelProperties,
        ...capiProperties,
        customProperties,
      });

      // New pixel events
      const newPixelEventId = generateEventId(userId);
      fbq(
        "trackSingle",
        NOOM_WEIGHT_META_PIXEL,
        "PurchasedAddOns",
        {
          ...pixelProperties,
          products,
        },
        {
          newPixelEventId,
        }
      );

      capiProperties.eventID = newPixelEventId;

      fireFacebookCapi(NOOM_WEIGHT_META_PIXEL, "PurchasedAddOns", {
        ...pixelProperties,
        ...capiProperties,
        customProperties,
      });
    }
  );

  registerHandler("noomSummit", ({ signupEltv13, products }) => {
    fbq("trackSingle", FB_ELTV13_PIXEL, "SummitUptake", {
      value: signupEltv13 + 100,
      products,
    });
    fbq("trackSingle", NOOM_WEIGHT_META_PIXEL, "SummitUptake", {
      value: signupEltv13 + 100,
      products,
    });
  });
}
