/// <reference types="cypress" />
import i18n, { InitOptions } from "i18next";
import { getLanguage, getLocale } from "@utils/meristemContext";
import merge from "lodash/merge";

/**
 * Core language translations
 * */
import enUS from "./en-US/translations.json";
import esMX from "./es-MX/translations.json";
import deDE from "./de-DE/translations.json";

/**
 * Locale-specific translations
 *
 * NOTE: All translations should be first added to the "core"
 * language files. These files are only meant to override
 * locale-specific differences (i.e. Color vs Colour).
 * See docs/translations.md for more information.
 * */
import enAU from "./en-AU/translations.json";
import enCA from "./en-CA/translations.json";
import enGB from "./en-GB/translations.json";
import enIE from "./en-IE/translations.json";
import enNZ from "./en-NZ/translations.json";

/**
 * Note: Since the launch of our new TMS, Smartling, "international" / generic
 * flavors are considered translations, overriding the core translations
 * locale-specific phrases in the core files to be suitable for more generic
 * audiences.
 *
 * To learn more, check out the following section in Coda:
 * https://coda.io/d/_dsq3pBd5PUH/Working-with-translations-an-engineering-guide_suFO0#_lubqB
 */
import enOverrides from "./enOverrides/translations.json";
import deOverrides from "./deOverrides/translations.json";
import esOverrides from "./esOverrides/translations.json";

// TODO: Nix these once we move the tax term out of translations
import enMX from "./en-MX/translations.json";
import enPK from "./en-PK/translations.json";
import enSG from "./en-SG/translations.json";

import { appConfig } from "src/config";
import { trackEvent } from "src/utils/api/tracker";
import { formatPrice } from "src/utils/priceUtils";
import getStore from "src/utils/redux/store";

export enum LanguageCode {
  EN = "en",
  ES = "es",
  DE = "de",
  JA = "ja",
  KO = "ko",
}

const locale = getLocale();

const settings: InitOptions = {
  lng: locale,
  fallbackLng: "en",
  saveMissing: true,

  postProcess: ["logMissingTranslations"],
  postProcessPassResolved: true,

  debug:
    __NODE_ENV__ !== "production" &&
    __NODE_ENV__ !== "test" &&
    typeof window !== "undefined",
  interpolation: {
    escapeValue: false,
    defaultVariables: {
      BLOG_DOMAIN: appConfig.BLOG_DOMAIN,
    },
  },
  react: {
    // Enables keeping the name of simple nodes (e.g. <br/>) in translations instead of indexed keys
    transSupportBasicHtmlNodes: true,
    // TODO: Drop ul and li once question sets support richer data structures
    transKeepBasicHtmlNodesFor: [
      "br",
      "strong",
      "i",
      "p",
      "ul",
      "li",
      "u",
      "sup",
    ],
  },
  resources: {
    en: merge({}, enUS, enOverrides),
    es: merge({}, esMX, esOverrides),
    de: merge({}, deDE, deOverrides),
    "en-AU": enAU,
    "en-CA": enCA,
    "en-GB": enGB,
    "en-IE": enIE,
    "en-NZ": enNZ,
    "en-US": enUS,
    "es-MX": esMX,
    "de-DE": deDE,

    "en-MX": enMX,
    "en-PK": enPK,
    "en-SG": enSG,
  },
};

i18n.use({
  type: "postProcessor",
  name: "logMissingTranslations",
  process: (value, key, options, translator) => {
    // Adding this condition to throw an error during jest and cypress test runs to catch any missing translation keys
    const isTestingEnv =
      __NODE_ENV__ === "test" || typeof Cypress !== "undefined";

    if (
      options.i18nResolved.res === undefined &&
      value !== options.i18nResolved.usedKey
    ) {
      // looks like default value was used
      return value;
    }

    const isMissing =
      options.i18nResolved.res === undefined ||
      !translator.language.startsWith(options.i18nResolved.usedLng);

    const i18nLanguage = translator.language;
    const fallback = options.i18nResolved.usedLng;

    if (isMissing && __NODE_ENV__ === "production") {
      const eventData = {
        i18nLanguage,
        key,
        fallback,
      };
      trackEvent("MissingTranslationError", eventData);
    } else if (isMissing && !isTestingEnv) {
      // Soft failure to reduce Sentry noise in development.
      // Disabled in testing env to reduce noise
      // eslint-disable-next-line no-console
      console.error(
        "Key Missing!",
        "Language:",
        i18nLanguage,
        "Key:",
        key,
        "Fallback:",
        fallback,
        "i18nResolved:",
        options.i18nResolved
      );
    }
    return value;
  },
});

i18n.init(settings);

i18n.services.formatter.add("formatPrice", (value, _, options) => {
  const { recommendedPlan, serverContext } = getStore().getState();
  const currency =
    options?.currency || serverContext?.currency || recommendedPlan?.currency;
  // if the value is not a valid number, return the value as is
  if (isNaN(+value)) {
    return value;
  }
  return formatPrice(value, currency, options?.showTrailingZeroes);
});

i18n.services.formatter.add("formatDuration", (value, _, options) => {
  return i18n.t(`duration:${options?.type || "month"}`, { count: value });
});

i18n.services.formatter.add("formatPlanDuration", (value, _, options) => {
  return i18n.t(`planDuration:${options?.type || "month"}`, { count: value });
});

export function isStringPresentable(str: string) {
  if (str.startsWith("t:")) {
    return isTokenPresentable(str.substring(2));
  }

  // This is a string literal. No translation so assume that it is
  // intended for all users.
  return true;
}

/**
 * Translate the given token if it can be shown in the current language stack.
 */
export function ifPresentable(str: string) {
  return isStringPresentable(str) ? str : null;
}

/**
 * Checks if a given i18n token can be shown in the current language stack.
 */
export function isTokenPresentable(token: string) {
  return i18n.exists(token, {
    lngs: [i18n.resolvedLanguage, getLanguage()],
  });
}

export function isValidLocale(test?: string) {
  // Fetch only the pure language code from the locale to support Google
  // search results that direct to noom.com/de-DE
  const languageCode = test?.split(/-|_/)[0];
  return languageCode.toLowerCase() in i18n.store.data;
}

export default i18n;
