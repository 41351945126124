import visitTracker from "@noom/visit-tracker";
import { registerMonitorProvider } from "../prop-registry";

registerMonitorProvider("monitoring", () => {
  // Duplicates buyflow worker, but this data will be more accurate based on the
  // session state for the user.
  const urlParams = visitTracker.getTrackingParams();

  const utmParamsMapping = {
    utm_source: "UTM Source - Current",
    utm_medium: "UTM Medium - Current",
    utm_campaign: "UTM Campaign - Current",
    utm_content: "UTM Content - Current",
    utm_term: "UTM Term - Current",
    referrer: "Referrer URL",
  };
  const mixpanelProperties: JsonObject = {};
  Object.keys(urlParams || {}).forEach((paramKey) => {
    if (paramKey in utmParamsMapping) {
      const mappingKey = paramKey as keyof typeof utmParamsMapping;
      mixpanelProperties[utmParamsMapping[mappingKey]] = urlParams[paramKey];
    } else {
      mixpanelProperties[paramKey] = urlParams[paramKey];
    }
  });
  return mixpanelProperties;
});
