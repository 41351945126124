import getStore from "../redux/store";
import { captureException } from "../error";
import { genderConstants, sexConstants } from "../sexAndGender";
import { getSurveyAnswers } from "src/hooks/survey/answers";
import { extractSubscriptionFromServerContext } from "../subscription";

export function isMaleGender(state = getStore().getState()) {
  const surveyAnswers = getSurveyAnswers(state);
  return surveyAnswers.gender?.[0] === genderConstants.male;
}

export function isFemaleGender(state = getStore().getState()) {
  const surveyAnswers = getSurveyAnswers(state);
  return (
    !surveyAnswers.gender || surveyAnswers.gender[0] === genderConstants.female
  );
}

export function isMaleSex(state = getStore().getState()) {
  const surveyAnswers = getSurveyAnswers(state);
  return !surveyAnswers.sex
    ? isMaleGender(state)
    : surveyAnswers.sex[0] === sexConstants.male;
}

export function isFemaleSex(state = getStore().getState()) {
  const surveyAnswers = getSurveyAnswers(state);
  return !surveyAnswers.sex
    ? isFemaleGender(state)
    : surveyAnswers.sex[0] === sexConstants.female;
}

export const isOver30DaySubscriber = () => {
  try {
    const subscription = extractSubscriptionFromServerContext();

    const startDate = new Date(
      subscription.time_started.year,
      subscription.time_started.month - 1 /* months are from 0 to 11 */,
      subscription.time_started.day
    );

    const currentDate = new Date();
    const timeDifference = currentDate.getTime() - startDate.getTime();

    const daysDifference = timeDifference / (1000 * 3600 * 24);

    return daysDifference >= 30;
  } catch (e) {
    captureException(e, "isOver30DaySubscriber");
    return false;
  }
};

export const hasVeryLowMobility = (state = getStore().getState()) => {
  const { surveyAnswers } = state;
  return (
    surveyAnswers?.fitonWorkoutPlanLimitations?.includes("troubleGettingUp") ||
    (surveyAnswers?.fitonWorkoutPlanLimitations?.includes("badJointsOrKnee") &&
      surveyAnswers?.fitonWorkoutPlanLimitations?.includes("cantJump"))
  );
};
