/**
 * Application main entry point.
 */
import React, { ComponentType } from "react";
import { createRoot } from "react-dom/client";

import ErrorBoundary from "./components/error/ErrorBoundary";
import { captureException } from "@utils/error";
import { initializeSentry } from "@utils/error/sentry";
import { preloadPagePath } from "./router";
import { triggerLoadPhase } from "./vendor";
import { PageSetRouter } from "./pageDefinitions/components/PageSetRouter";
import { measureTime } from "./utils/timing";

// Set up sentry error handling so we can capture any errors as early as possible.
initializeSentry();

(window as any).serverContext = {};

export async function boot(App: ComponentType): Promise<void> {
  try {
    measureTime("bf-boot", "bf-exec-start");

    // Preload the current page chunk
    preloadPagePath(window.location.pathname);

    triggerLoadPhase("bootstrap");

    const container = document.getElementById("root");
    const root = createRoot(container);

    root.render(
      <ErrorBoundary>
        <PageSetRouter>
          <App />
        </PageSetRouter>
      </ErrorBoundary>
    );
  } catch (err) {
    captureException(err, "bootstrap");
  }
}
