import {
  healthRiskOptions,
  oralsHealthRiskOptions,
} from "src/pages/survey/currentHealthRiskMedical";

export const MAX_COPAY_DEDUCTIBLE_TO_DISPLAY_AMOUNT = 40;

export enum PatientIntakeQuestionType {
  a1c = "a1c",
  address = "address",
  alcoholFrequency = "alcoholFrequency",
  altoPharmacy = "altoPharmacy",
  bariatricSurgery = "bariatricSurgery",
  historyBariatricSurgery = "historyBariatricSurgery",
  bloodPressure = "bloodPressure",
  choosePharmacy = "choosePharmacy",
  conditions = "conditions",
  confirmUser = "confirmUser",
  eatingDisorder = "eatingDisorder",
  familyHistory = "familyHistory",
  heartRate = "heartRate",
  heightWeight = "heightWeight",
  labs = "labs",
  labUploader = "labUploader",
  medication = "medication",
  useMedications = "useMedications",
  medicationAllergies = "medicationAllergies",
  hasMedicationAllergies = "hasMedicationAllergies",
  medicationAllergiesList = "medicationAllergiesList",
  medicationList = "medicationList",
  medications = "medications",
  notAvailable = "notAvailable",
  originalConditions = "originalConditions",
  otherSurgeries = "otherSurgeries",
  hasOtherSurgeries = "hasOtherSurgeries",
  otherSurgeriesList = "otherSurgeriesList",
  patientAcknowledgement = "patientAcknowledgement",
  patientAttestation = "patientAttestation",
  phoneNumber = "phoneNumber",
  pregnantOrBreastfeeding = "pregnantOrBreastfeeding",
  searchPharmacy = "searchPharmacy",
  selfAssessedHealth = "selfAssessedHealth",
  suicideOrSelfHarm = "suicideOrSelfHarm",
  useDrugs = "useDrugs",
  useTobacco = "useTobacco",
  // Below are SteadyMD Intake
  familyHistoryThyroidCancer = "familyHistoryThyroidCancer",
  conditionsExisting = "conditionsExisting",
  bloodPressureSimple = "bloodPressureSimple",
  heartRateSimple = "heartRateSimple",
  lifestyleImpacts = "lifestyleImpacts",
  suicideOrSelfHarm1 = "suicideOrSelfHarm1",
  suicideOrSelfHarm2 = "suicideOrSelfHarm2",
  // SteadyMD Insurance Intake
  insuranceCoverage = "insuranceCoverage",
  insurancePayer = "insurancePayer",
  insuranceIsPrimarySubscriber = "insuranceIsPrimarySubscriber",
  insurancePrimarySubscriberName = "insurancePrimarySubscriberName",
  insurancePrimarySubscriberDob = "insurancePrimarySubscriberDob",
  insuranceMemberId = "insuranceMemberId",
  insuranceGroupId = "insuranceGroupId",
  insuranceRxBin = "insuranceRxBin",
  insuranceRxPcn = "insuranceRxPcn",
  insuranceRxGroup = "insuranceRxGroup",
  // Below are keys that are returned from requiredPatientIntake
  // The discrepancy above is that we may get back keys that dont send
  // i.e. "bmi" but still want to handle as PatientIntakeQuestion types
  bmi = "bmi",
  height = "height",
}

export const enum ConsultType {
  EM = "EM",
  RPM = "RPM",
  VIDEO = "VIDEO",
}

export enum StatesLabsNotIncluded {
  NY = "NY",
  RI = "RI",
  NJ = "NJ",
}

export enum CompoundedPlanIneligibleStates {
  MS = "MS",
  LA = "LA",
}

export enum CompoundedPlanIncreasedBMIStates {
  NJ = "NJ",
  FL = "FL",
}

export enum OralsPlanIneligibleStates {
  MS = "MS",
  LA = "LA",
}

export enum BrandedPlanIneligibleStates {
  AL = "AL",
  VA = "VA",
}

export enum MedEligibleCountries {
  US = "US",
}

export const INELIGIBLE_HEALTH_RISKS: Array<typeof healthRiskOptions[number]> =
  ["type1Diabetes"];

export const BRANDED_PLAN_INELIGIBLE_HEALTH_RISKS: Array<
  typeof healthRiskOptions[number]
> = ["activeHeartDiseaseOrFailure", "liverFailure", "cancerUnderTreatment"];

export const COMPOUNDED_PLAN_INELIGIBLE_HEALTH_RISKS: Array<
  typeof healthRiskOptions[number]
> = ["pancreatitis", "men2", "mtc", "benzylAlcoholAllergy"];

export const ORALS_PLAN_INELIGIBLE_HEALTH_RISKS: Array<
  typeof oralsHealthRiskOptions[number]
> = [
  "liverDisease",
  "kidneyDisease",
  "metabolicAcidosis",
  "metformin",
  "pancreatitis",
];

export const MED_BMI_THRESHOLD_ORALS = 25;
export const MED_BMI_THRESHOLD_COMPOUNDED = 25;
export const MED_BMI_INCREASED_THRESHOLD_COMPOUNDED = 27;
export const MED_BMI_THRESHOLD_BRANDED = 27;

export enum SurveyType {
  // create a new consult
  Schedule = "Schedule",
  // move an existing consult to a new time
  Reschedule = "Reschedule",
}

export const MDI_CONSENT_URL = "https://mdintegrations.com/tos";
