import { captureException } from "src/utils/error";
import {
  getAndroidMessageHandlers,
  getIOSMessageHandlers,
} from "src/utils/in-app/messageHandlers";
import { isInsideAndroidWebView, isInsideIOSWebKit } from "./utils";
import { createForwardParams } from "src/utils/urlParams";
import { reroll } from "../components/PageSetRouter";
import goto from ".";

export default {
  nativeProgramSwitchComplete() {
    if (isInsideIOSWebKit()) {
      try {
        const messageHandlers = getIOSMessageHandlers();
        messageHandlers?.programSwitchComplete?.postMessage({});
        return true;
      } catch (err) {
        // istanbul ignore next Error handling
        captureException(err, "nativeProgramSwitchComplete.ios");
      }
    }
    if (isInsideAndroidWebView()) {
      try {
        const messageHandlers = getAndroidMessageHandlers();
        messageHandlers.programSwitchComplete();
        return true;
      } catch (err) {
        // istanbul ignore next Error handling
        captureException(err, "nativeProgramSwitchComplete.android");
      }
    }

    // eslint-disable-next-line no-console
    console.error("nativeProgramSwitchComplete: no message handler found");

    return false;
  },
  exitCourseSurvey() {
    if (!goto.nativeProgramSwitchComplete()) {
      goto.landing();
    }
  },
  cancelSubscriptionFromCourseSurvey() {
    const searchParams = new URLSearchParams(window.location.search);
    const subscription_id = searchParams.get("subscription_id");
    const cancelFlowURL = `/cancel/?subscription_id=${subscription_id}`;
    window.location.href = cancelFlowURL;
  },
  courseErrorPage() {
    const params = createForwardParams();
    const errorUrl = "/course/error";
    reroll(errorUrl, params, true);
  },
};
