import { reasonConstants } from "../redux/slices/serverContext";
import getStore from "../redux/store";

export function isEligibleForCourseHwSwitchBackQ() {
  const { serverContext } = getStore().getState();
  return serverContext.reason !== reasonConstants.revert;
}

export function isEligibleForCourseHwRenewal() {
  const { serverContext } = getStore().getState();
  return serverContext.reason === reasonConstants.renewal;
}
