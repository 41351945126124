import { ActionHandler } from "@pageDefinitions/actions";
import {
  getMhtUserRolloutStatus,
  isEligibleForMhtBuyflow,
  isMhtEnabled,
  isUserSegmentEligibleForMhtBuyflow,
} from "@utils/userSegment/features";
import { trackEvent } from "@utils/api/tracker";
import { isFemaleSex } from "@utils/userSegment";

export const trackHRTRolloutState: ActionHandler = () => {
  // Note(Anastasiya): This event should be removed after the full rollout of MHT feature
  if (isUserSegmentEligibleForMhtBuyflow() && isMhtEnabled() && isFemaleSex()) {
    trackEvent("MedHRTRolloutState", {
      userRolloutStatus: getMhtUserRolloutStatus(),
      isEligibleForHRT: isEligibleForMhtBuyflow(),
    });
  }
};
